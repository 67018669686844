import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryAddPotentialPatient, tryEditPotentialPatient, tryDeletePotentialPatient } from "../../actions/questions";
import { BreadcrumbStep, DefaultLoader } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";

import BreakScoreQuestionnaireForm1 from "../../components/BreakScoreQuestionnaire/BreakScoreQuestionnaireForm1";
import BreakScoreQuestionnaireForm2 from "../../components/BreakScoreQuestionnaire/BreakScoreQuestionnaireForm2";
import Step3 from "./Steps/Steps3";
import useState from "./useState";
import Questions from "../../actions/questions";

const { tryFetchBreakScoreNoInsulinQuestions, tryFetchBreakScoreQuestions } = Questions;

const breadcrumbs = [{ label: "Questionnaire de rupture" }];

const steps = ["Informations", "Questions", "Résultat"];

const BreakScoreQuestionnaireScreen = ({
    tryFetchBreakScoreNoInsulinQuestions,
    tryFetchBreakScoreQuestions,
    auth,
    questions: { breakScoreQuestions, breakScoreQuestionsLoading, createLoading, editLoading, deleteLoading },
    tryAddPotentialPatient,
    tryEditPotentialPatient,
    tryDeletePotentialPatient,
}) => {
    const [{ loading, currentStep, isEligible, patient }, { load, prevStep, nextStepFrom0To1, nextStepFrom1To2 }] = useState(breakScoreQuestions.length === 0);

    const [isNonInsulinoQuestions, setNonInsulinoQuestions] = React.useState([]);
    const [insulinoQuestions, setInsulinoQuestions] = React.useState([]);
    const [isNonInsulino, setNonInsulino] = React.useState(false);
    React.useEffect(() => {
        if (loading && !breakScoreQuestionsLoading) {
            load();
        }
    }, [breakScoreQuestionsLoading]);

    React.useEffect(() => {
        tryFetchBreakScoreNoInsulinQuestions()
            .then((breakScoreQuestions) => {
                breakScoreQuestions.map((question) => {
                    if (question.active) {
                        setNonInsulinoQuestions((isNonInsulinoQuestions) => [...isNonInsulinoQuestions, question]);
                    }
                });
            })
            .catch(load);
        tryFetchBreakScoreQuestions().then((breakScoreQuestions) => {
            breakScoreQuestions.map((question) => {
                if (question.active) {
                    setInsulinoQuestions((insulinoQuestions) => [...insulinoQuestions, question]);
                }
            });
        });
    }, []);

    const onSubmit = React.useCallback(
        (responses) => {
            if (createLoading) {
                return;
            }
            const whichQuestions = isNonInsulino ? insulinoQuestions : isNonInsulinoQuestions;
            const whichType = isNonInsulino ? "break-score" : "break-score-no-insulin";
            const answers = whichQuestions.map(({ label }, i) => {
                const responseKey = `response${i}`;
                const response = responses[responseKey];

                return {
                    values: [response],
                    question: label,
                };
            });
            const params = {
                ...patient,
                type: whichType,
                answers,
            };

            if (params.id) {
                delete params.id;
                delete params.filledBy;
            }
            tryAddPotentialPatient(params)
                .then((p) => nextStepFrom1To2(p, answers))
                .catch(() => nextStepFrom1To2(true, answers));
        },
        [patient, createLoading]
    );

    const onSave = React.useCallback(
        (data) => {
            data.practitioner = { id: data.practitioner };
            return tryEditPotentialPatient(patient, data);
        },
        [patient]
    );

    const onDelete = React.useCallback(() => {
        if (patient.id) {
            tryDeletePotentialPatient(patient.id);
        }
    }, [patient.id]);

    const stepRender = React.useMemo(() => {
        if (loading) {
            return <DefaultLoader />;
        }

        switch (currentStep) {
            case 0:
                return <BreakScoreQuestionnaireForm1 onSubmit={nextStepFrom0To1} patient={patient} setNonInsulino={setNonInsulino} />;
            case 1:
                return (
                    <BreakScoreQuestionnaireForm2
                        onSubmit={onSubmit}
                        prevStep={prevStep}
                        questions={isNonInsulino ? insulinoQuestions : isNonInsulinoQuestions}
                        isLoading={createLoading}
                        answers={patient.answers}
                    />
                );
            case 2:
                return (
                    <Step3
                        prevStep={prevStep}
                        onSubmit={console.log}
                        isEligible={isEligible}
                        isLoading={editLoading || deleteLoading}
                        accessToken={auth.accessToken}
                        onSave={onSave}
                        onDelete={onDelete}
                        patient={patient}
                        tryEditPotentialPatient={tryEditPotentialPatient}
                        auth={auth}
                    />
                );
            default:
                return undefined;
        }
    }, [currentStep, loading, createLoading, onSubmit]);

    return (
        <AppLayout navActive={2} breadcrumbs={breadcrumbs}>
            <header className="headerPage">
                <div className="headerPage__title">
                    <h1 className="title-1">Questionnaire d'identification de nouveaux patients</h1>
                </div>
                <div className="headerPage__actions">
                    <BreadcrumbStep index={currentStep} steps={steps} />
                </div>
            </header>
            {stepRender}
        </AppLayout>
    );
};

BreakScoreQuestionnaireScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    tryAddPotentialPatient: PropTypes.func.isRequired,
    tryEditPotentialPatient: PropTypes.func.isRequired,
    tryDeletePotentialPatient: PropTypes.func.isRequired,
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired,
    tryFetchBreakScoreQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, questions }) => ({ auth, questions });

const mapDispatchToProps = {
    tryAddPotentialPatient,
    tryEditPotentialPatient,
    tryDeletePotentialPatient,
    tryFetchBreakScoreNoInsulinQuestions,
    tryFetchBreakScoreQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakScoreQuestionnaireScreen);
