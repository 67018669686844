import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";
import auth from "../reducers/auth";

const questions = {};
for (const questionnaireType of types.QUESTIONNAIRE_TYPES) {
    const _type = questionnaireType.toUpperCase().replace(/-/g, "_");
    const pascalType = questionnaireType.replace(/((?:^|-)[a-z]{1})/g, (w) => w.substring(w.length - 1).toUpperCase());

    questions[`tryFetch${pascalType}Questions`] =
        (active = undefined) =>
        (dispatch) => {
            const queryParams = {
                type: questionnaireType,
                pagination: false,
            };
            const {
                auth: { accessToken },
            } = store.getState();

            if (active !== undefined) {
                queryParams.active = active;
            }
            dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_FETCH_REQUESTED`] });
            return API.request({
                path: isLocalAPI ? "/questions" : "/dap-admin/api/v1/questionnaires/questions",
                queryParams,
                token: accessToken,
            })
                .then((response) => {
                    const questions = response["hydra:member"];
                    dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_FETCH_SUCCESS`], data: questions });
                    return questions;
                })
                .catch((error) => dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_FETCH_FAILURE`], data: error }));
        };

    questions[`tryAdd${pascalType}Question`] = (question) => (dispatch) => {
        dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_ADD_REQUESTED`] });
        const {
            auth: { accessToken },
        } = store.getState();
        return API.request({
            path: isLocalAPI ? "/questions" : "/dap-admin/api/v1/questionnaires/questions",
            token: accessToken,
            method: "POST",
            data: {
                ...question,
                type: questionnaireType,
            },
        })
            .then((response) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_ADD_SUCCESS`], data: response });
                return questions[`tryFetch${pascalType}Questions`](auth);
            })
            .catch((error) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_ADD_FAILURE`], data: error });
                throw error;
            });
    };

    questions[`tryEdit${pascalType}Question`] = (questionId, question) => (dispatch) => {
        const {
            auth: { accessToken },
        } = store.getState();
        dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_EDIT_REQUESTED`] });
        return API.request({
            path: isLocalAPI ? "/questions/{id}" : "/dap-admin/api/v1/questionnaires/questions/{id}",
            uriParams: {
                id: questionId,
            },
            token: accessToken,
            method: "PUT",
            data: question,
        })
            .then((response) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_EDIT_SUCCESS`], data: response });
                return questions[`tryFetch${pascalType}Questions`](auth);
            })
            .catch((error) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_EDIT_FAILURE`], data: error });
                throw error;
            });
    };

    questions[`tryActivate${pascalType}Question`] = (question, active) => (dispatch) => {
        const {
            auth: { accessToken },
        } = store.getState();
        dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_ACTIVATE_REQUESTED`] });
        return API.request({
            path: isLocalAPI ? "/questions/{id}" : "/dap-admin/api/v1/questionnaires/questions/{id}",
            uriParams: {
                id: question.id,
            },
            token: accessToken,
            method: "PUT",
            data: {
                ...question,
                active,
            },
        })
            .then((response) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_ACTIVATE_SUCCESS`], data: response });
                return questions[`tryFetch${pascalType}Questions`](auth);
            })
            .catch((error) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_ACTIVATE_FAILURE`], data: error });
                throw error;
            });
    };

    questions[`trySwap${pascalType}QuestionOrder`] = (questionOne, questionTwo) => (dispatch) => {
        const {
            auth: { accessToken },
        } = store.getState();
        dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_SWAP_REQUESTED`] });
        return API.request({
            path: isLocalAPI ? "/questions/{id}" : "/dap-admin/api/v1/questionnaires/questions/{id}",
            uriParams: {
                id: questionOne.id,
            },
            token: accessToken,
            method: "PUT",
            data: {
                ...questionOne,
                order: questionTwo.order,
            },
        })
            .then(() =>
                dispatch({
                    type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_SWAP_SUCCESS`],
                    data: {
                        questionOne: {
                            ...questionOne,
                            order: questionTwo.order,
                        },
                        questionTwo: {
                            ...questionTwo,
                            order: questionOne.order,
                        },
                    },
                })
            )
            .catch((error) => dispatch({ type: types.QUESTIONNAIRES[`${_type}_QUESTIONS_SWAP_FAILURE`], data: error }));
    };
}

for (const questionnaireAnswersType of types.QUESTIONNAIRE_ANSWERS_TYPES) {
    const _type = questionnaireAnswersType.toUpperCase().replace(/-/g, "_");
    const pascalType = questionnaireAnswersType.replace(/((?:^|-)[a-z]{1})/g, (w) => w.substring(w.length - 1).toUpperCase());
    const camelType = questionnaireAnswersType.replace(/(-[a-z]{1})/g, (w) => w.substring(w.length - 1).toUpperCase());

    questions[`tryFetch${pascalType}Answers`] = (potentialPatientId) => (dispatch) => {
        const {
            auth: { accessToken },
            questions,
        } = store.getState();

        if (questions[`${camelType}AnswersLoaded`] && questions[`${camelType}AnswersPotentialPatientId`] === potentialPatientId) {
            return Promise.resolve(questions[`${camelType}Answers`]);
        }

        dispatch({ type: types.QUESTIONNAIRES[`${_type}_ANSWERS_FETCH_REQUESTED`] });
        return API.request({
            path: isLocalAPI ? "/answers" : "/dap-admin/api/v1/questionnaires/answers",
            queryParams: {
                type: questionnaireAnswersType,
                pagination: false,
                "potentialPatient.id": potentialPatientId,
                active: true,
            },
            token: accessToken,
        })
            .then((response) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_ANSWERS_FETCH_SUCCESS`], data: response["hydra:member"], potentialPatientId });
                return response["hydra:member"];
            })
            .catch((error) => {
                dispatch({ type: types.QUESTIONNAIRES[`${_type}_ANSWERS_FETCH_FAILURE`], data: error });
                throw error;
            });
    };
}

export const tryAddPotentialPatient = (potentialPatient) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.ADD_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients" : "/dap-admin/api/v1/break-score-questionnaires/potential-patients",
        token: accessToken,
        method: "POST",
        data: {
            allowDataUsageForStudy: true,
            firstName: potentialPatient.firstName,
            lastName: potentialPatient.lastName,
            birthDate: potentialPatient.birthDate,
            questionnaire: {
                type: potentialPatient.type,
                answers: potentialPatient.answers,
            },
            identifiedBy: potentialPatient.identifiedBy,
        },
    })
        .then((response) => {
            dispatch({ type: types.ADD_POTENTIAL_PATIENT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.ADD_POTENTIAL_PATIENT_FAILURE, data: error });
            throw error;
        });
};

export const tryEditPotentialPatient = (potentialPatient, data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.EDIT_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-admin/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatient.id,
        },
        method: "PUT",
        token: accessToken,
        data: {
            ...potentialPatient,
            answers: potentialPatient.answers.map((answer) => ({ id: answer["@id"] })),
            consent: potentialPatient?.consent?.["@id"],
            filledBy: potentialPatient.filledBy["@id"],
            ...data,
            pointOfContact: data.pointOfContact === "" ? null : data.pointOfContact,
            identifiedBy: data.identifiedBy === "" ? null : data.identifiedBy,
        },
    })
        .then((response) => {
            dispatch({ type: types.EDIT_POTENTIAL_PATIENT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.EDIT_POTENTIAL_PATIENT_FAILURE, data: error });
            throw error;
        });
};

export const tryDeletePotentialPatient = (potentialPatientId) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.DELETE_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-admin/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatientId,
        },
        method: "DELETE",
        token: accessToken,
    })
        .then(() => dispatch({ type: types.DELETE_POTENTIAL_PATIENT_SUCCESS }))
        .catch(() => dispatch({ type: types.DELETE_POTENTIAL_PATIENT_FAILURE }));
};

export default questions;
